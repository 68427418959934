    <template>
  <div>
    
    <div class="col-12">
    <div class="m-2">
        <Button class="ml-2" label="VOLVER" @click="this.$router.go(-1)" />
    </div>
      <div class="card" :key="index">
        <div class="flex justify-content-between flex-wrap">
          <div>
            <h1>{{ otiName(order.id) }}</h1>
          </div>
          <div class="">
            <Button class="ml-2" label="" />
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">

            <div class="field col">
              <label for="detail">PIEZA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.piece }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.quantity }}
              </span>
            </div>
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">MATERIAL</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.material, 'material') }}
              </span>
            </div>


            <div class="field col">
              <label for="detail">TRATAMIENTO</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeTratamiento(order.tratamiento) }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">MAQUINA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.machine_id, 'maquinaria') }}
              </span>
            </div>
          </div>
        </div>
        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">PLANOS</label>

              <div>
                <div v-for="(plan, index) in order.plans" :key="index">
                  <Button
                    :label="plan.name"
                    icon="pi pi-file-pdf"
                    iconPos="left"
                    class="btn plan_button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="">
    TABLA DE CONTROL
</div> -->

        <div class="mt-3">
          <div class="col-12">
            <OtiClock
              :order="order"
            />
          </div>
        </div>
        <!-- 
				<p>{{ order }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import OtiClock from "./../../components/OtiClock.vue";
import CrudService from "./../../services/crud.service.js" 

export default {
  components: {
    OtiClock,
  },
  data() {
    return {
      order: [],
      machines: [],
      clients: [],
      users: [],
      materials: [],
      tratamientos: [
        { id: 1, name: "Tratamiento 1" },
        { id: 2, name: "Tratamiento 2" },
        { id: 3, name: "Tratamiento 3" },
        { id: 4, name: "Tratamiento 4" },
      ],
    };
  },
  created() {

  },
  mounted() {
    this.getOrder();
  },
  methods: {
    otiName(id) {
      // str pad left 0000
      return "#"+ ("000" + this.order.production_id).slice(-3) + "-" + ("0000" + id).slice(-4);
    },
      
    getOrder() {
        CrudService.getCRUD("cliente").then((data) => (this.clients = data));
        CrudService.getCRUD("usuario").then((data) => (this.users = data));
        CrudService.getCRUD("maquinaria").then((data) => (this.machines = data));
        CrudService.getCRUD("material").then((data) => (this.materials = data));
        CrudService.getCRUD("tratamiento").then((data) => (this.tratamientos = data));
        CrudService.getCRUD("orden", this.$route.params.id).then((data) => (this.order = data));
    },
    giveMeTratamiento(id) {
      return this.tratamientos[id]?.name;
    },
    giveMeName(id, table) {

      if(table == 'cliente'){
        let exist = this.clients.find((client) => client.id == id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'maquinaria') {
        let exist = this.machines.find((machine) => machine.id == id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'usuario') {
        let exist = this.users.find((user) => user.id == id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'material') {
        let exist = this.materials.find((material) => material.id == id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

    


    },
  },
};
</script>

<style scoped>
.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}
</style>